<template>
  <div class="feedback__container">
    <div class="feedback-fixed__header" @click="showForm = true">
      <div class="header__bt">
        <svg-icon icon-class="icon-plus"></svg-icon>
        <p>新增反馈</p>
      </div>
    </div>
    <div class="feedback-main__body">
      <div class="body__inner">
        <template v-if="feedbacks">
          <feedback-item theme="feedback" v-for="item in feedbacks" :key="item.id" :info="item" :isStranger="false"
            :call-post-del="handlerPostDelete" @postDel="onPostDel" @postReply="onPostReply"></feedback-item>
        </template>
      </div>
      <empty style="margin-top: 275px;" v-if="isEmpty" hint="反馈内容会出现在这里" btn-text="新增反馈"
        :click-action="() => showForm = true"></empty>
      <slogan v-else text="您的反馈是进步的源泉 非常感谢！"></slogan>
    </div>
    <router-link class="feedback-fixed__footer" tag="div" :to="{
      path: '/instructions',
    }">返回</router-link>
    <!-- form框 -->
    <div class="feedback-create__form edit-form-container" v-if="showForm">
      <van-form @submit="onSubmit" class="post-edit-form">
        <div class="form__item">
          <van-field v-model="form.content" class="textarea" type="textarea" maxlength="500" placeholder="提出您宝贵的意见..."
            show-word-limit :rules="[
              {
                required: true,
                message: '请填写内容',
              },
            ]">
            <template #label>
              <p><span>1、</span>反馈内容</p>
            </template>
          </van-field>
        </div>
        <div class="form__item">
          <van-field>
            <template #input>
              <wx-upload prefix="timeline-post" :limit="9" @onChange="onUploadChange"></wx-upload>
            </template>
          </van-field>
        </div>
        <!-- 底部导航 -->
        <div class="bottom-menu-container">
          <van-button class="form-cancel-bt" native-type="button" @click.native="goBack">取消</van-button>
          <van-button class="form-submit-bt" :loading="requesting" loading-text="提交中..."><strong>确定</strong></van-button>
        </div>
      </van-form>
    </div>
    <!-- 回复表单 -->
    <reply-form theme="feedback" @afterReply="onAfterReply" @cancel="onReplyFormCancel" :post-link="replyPostLink"
      :ex-info="exInfo"></reply-form>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import wxUpload from '../common/_upload-bt.vue';
import feedbackItem from '../timeline/_item.vue';
import replyForm from '../common/_reply-form.vue';
import slogan from '../common/_slogan.vue';
import empty from '../common/_empty.vue';

export default {
  name: 'views-user-feedback',
  components: {
    wxUpload,
    feedbackItem,
    replyForm,
    slogan,
    empty,
  },
  watch: {
    $route: {
      handler() {
        this.getData();
      },
      immediate: true,
    },
    feedbacks: {
      handler(val) {
        this.isEmpty = val && val.length === 0;
      },
      immediate: true,
    },
  },
  data() {
    return {
      form: {
        content: '',
        imgs: '',
      },
      requesting: false,
      showForm: false,
      replyPostLink: '',
      feedbacks: null,
      exInfo: {},
      isEmpty: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapMutations(['OPEN_REPLY_FORM', 'CLOSE_REPLY_FORM']),
    getData() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get('api/user/account/feedbacks')
          .then((res) => {
            that.feedbacks = res.data;
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onUploadChange(val) {
      this.form.imgs = val.length > 0 ? val : '';
    },
    onSubmit() {
      const that = this;
      const data = {
        content: that.form.content,
        imgs: that.form.imgs,
      };
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .post('api/user/account/feedbacks', data)
          .then((res) => {
            // 跳转到timelin详情
            const feedback = res.data;
            if (that.feedbacks === null) {
              that.feedbacks = [feedback];
            } else {
              that.feedbacks.unshift(feedback);
            }
            that.$notify({
              type: 'success',
              message: '您的反馈已提交，我们会及时与您取得联络',
            });
            that.goBack();
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    goBack() {
      this.showForm = false;
      this.form.content = '';
      this.form.imgs = '';
    },
    onPostReply(data) {
      const that = this;
      const temp = data;
      that.exInfo = temp;
      that.replyPostLink = `api/user/account/feedback-reply/${data.postid}`;
      that.OPEN_REPLY_FORM();
    },
    onAfterReply(res) {
      const that = this;
      const {
        content,
        // eslint-disable-next-line camelcase
        created_at,
        fid,
        uid,
        // eslint-disable-next-line camelcase
        updated_at,
        id,
        imgs,
      } = res.data;
      const data = that.feedbacks.map((item) => {
        const temp = item;
        if (Number(item.id) === Number(that.exInfo.postid)) {
          temp.reply.push({
            author: {
              nickname: that.user.nickname,
              uid,
            },
            content,
            fid,
            id,
            uid,
            created_at,
            updated_at,
            imgs,
          });
        }
        return temp;
      });
      that.feedbacks = data;
      that.CLOSE_REPLY_FORM();
    },
    onReplyFormCancel() {
      this.CLOSE_REPLY_FORM();
    },
    handlerPostDelete(data) {
      const { postid, deleteUrl } = data;
      const url = deleteUrl.replace(/:fid/, postid);
      return new Promise((resolve) => {
        this.$request.del(url).then((res) => {
          console.log(`处理结束${res.data}`);
          resolve(data);
        });
      });
    },
    onPostDel(postid) {
      this.feedbacks = this.feedbacks.filter((item) => Number(item.id) !== Number(postid));
      this.$notify({
        type: 'success',
        message: '操作成功',
      });
    },
  },
};
</script>

<style scoped lang="scss">
.feedback__container {
  width: 100%;
  position: relative;
  background-color: #fff;
  min-height: 100vh;
}

.feedback-fixed__header {
  padding: 33px 32px;

  .header__bt {
    height: 64px;
    background: #F4F4F4;
    color: #bbb;
    font-size: 32px;
    border-radius: 10px;
  }
}

.feedback-fixed__footer {
  width: 100%;
  height: 98px;
  line-height: 98px;
  color: #000;
  font-size: 32px;
  text-align: center;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
