var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"feedback__container"},[_c('div',{staticClass:"feedback-fixed__header",on:{"click":function($event){_vm.showForm = true}}},[_c('div',{staticClass:"header__bt"},[_c('svg-icon',{attrs:{"icon-class":"icon-plus"}}),_c('p',[_vm._v("新增反馈")])],1)]),_c('div',{staticClass:"feedback-main__body"},[_c('div',{staticClass:"body__inner"},[(_vm.feedbacks)?_vm._l((_vm.feedbacks),function(item){return _c('feedback-item',{key:item.id,attrs:{"theme":"feedback","info":item,"isStranger":false,"call-post-del":_vm.handlerPostDelete},on:{"postDel":_vm.onPostDel,"postReply":_vm.onPostReply}})}):_vm._e()],2),(_vm.isEmpty)?_c('empty',{staticStyle:{"margin-top":"275px"},attrs:{"hint":"反馈内容会出现在这里","btn-text":"新增反馈","click-action":() => _vm.showForm = true}}):_c('slogan',{attrs:{"text":"您的反馈是进步的源泉 非常感谢！"}})],1),_c('router-link',{staticClass:"feedback-fixed__footer",attrs:{"tag":"div","to":{
    path: '/instructions',
  }}},[_vm._v("返回")]),(_vm.showForm)?_c('div',{staticClass:"feedback-create__form edit-form-container"},[_c('van-form',{staticClass:"post-edit-form",on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"form__item"},[_c('van-field',{staticClass:"textarea",attrs:{"type":"textarea","maxlength":"500","placeholder":"提出您宝贵的意见...","show-word-limit":"","rules":[
            {
              required: true,
              message: '请填写内容',
            },
          ]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',[_c('span',[_vm._v("1、")]),_vm._v("反馈内容")])]},proxy:true}],null,false,3022645098),model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('div',{staticClass:"form__item"},[_c('van-field',{scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('wx-upload',{attrs:{"prefix":"timeline-post","limit":9},on:{"onChange":_vm.onUploadChange}})]},proxy:true}],null,false,4124792927)})],1),_c('div',{staticClass:"bottom-menu-container"},[_c('van-button',{staticClass:"form-cancel-bt",attrs:{"native-type":"button"},nativeOn:{"click":function($event){return _vm.goBack.apply(null, arguments)}}},[_vm._v("取消")]),_c('van-button',{staticClass:"form-submit-bt",attrs:{"loading":_vm.requesting,"loading-text":"提交中..."}},[_c('strong',[_vm._v("确定")])])],1)])],1):_vm._e(),_c('reply-form',{attrs:{"theme":"feedback","post-link":_vm.replyPostLink,"ex-info":_vm.exInfo},on:{"afterReply":_vm.onAfterReply,"cancel":_vm.onReplyFormCancel}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }